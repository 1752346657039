import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="text-center">
        <h2 className="text-2xl font-bold">
          Page not found
        </h2>
        <p>
          <Link to="/">Click here to return to the homepage</Link>
        </p>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
